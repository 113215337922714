import React from 'react';

export default class Banner extends React.Component {

    render() {
        return (
            <div className="banner-wrapper">
                <div className={'banner-new banner-'+process.env.REACT_APP_WHICH_APP}>
                    <div className="container">
                        <div className="content">
                            <h1>
                                <span language="en">{process.env.REACT_APP_WHICH_APP} Accessories</span>
                                <span language="ar">ملحقات {process.env.REACT_APP_WHICH_APP}</span>
                            </h1>
                            <h3>
                                    <span language="ar">هنا تكتمل أناقة سيارتك</span>
                                    <span language="en">Accessorize in Style</span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}